import http from './http';
let url = '/api/adminApi'
export default {
    //             讲师管理

    // 创建讲师
    czcourseCateLst(data) {
        return http.post(url + '/admin/lecturer/create', data);
    },
    // 讲师列表
    lst(data) {
        return http.get(url + `/admin/lecturer/lst?searchValue=${data.searchValue}&limit=${data.limit}&page=${data.page}`);
    },
    // 讲师等级
    levelLst(data) {
        return http.get(url + '/tool/levelLst');
    },
    // 讲师删除
    del(data) {
        return http.get(url + `/admin/lecturer/del?id=${data.id}`);
    },
     // 讲师修改
     update(data) {
        return http.post(url + `/admin/lecturer/update`,data);
    },
     // 讲师预约列表
     reservationLst(data) {
        return http.get(url + `/admin/lecturer/reservationLst`);
    },
};
