import http from './http';
let url = '/api/adminApi/admin'
export default {
    //  视频库
    // 获取讲师列表
    lecturerLst(data) {
        return http.get('/api/adminApi/tool/lecturerLst', data);
    },
    // 视频列表
    getlst(data) {
        return http.get('/api/adminApi/admin/video/lst?searchValue=' + data.searchValue+'&page='+data.page+'&limit='+data.limit);
    },
     // 视频列表
     videoLst(data) {
        return http.get('/api/adminApi/tool/videoLst');
    },

};
