<template>
    <div v-Height>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'StudyPageStyle',
    props: {
        myheight: {
            type: String,
            default: 'calc(100vh - 120px)'
        },
        mypadding: {
            type: String,
            default: '50px'
        },
    },
    data() {
        return {
        };
    },
    mounted() { 
    },
    methods: {
    },
};
</script>
<style lang="scss">
.page-style {
    width: 100%;
    overflow-y: scroll;
    position: relative;
    // padding-bottom: 50px;
}
</style>