<template>
  <div id="app" ref="appRoot" style="transform: none">
    <router-view />
  </div>
</template>
<script>
import _ from "lodash";
export default {
  data() {
    return {
      windowHeight: null,
      screenWidth: 0,
    };
  },
  methods: {},
  mounted() {
    const appWidth = this.$refs.appRoot.clientWidth;
    window.initWidth = appWidth;
    this.$store.commit("setappWidth", appWidth);
  },
  beforeDestroy() {},
};
</script>
<style lang="scss">
/* 定义淡入淡出的过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.cursor {
  cursor: pointer;
}
body {
  overflow: hidden;
  font-size: 14px;
  background-color: #f5f5f5 !important;
}
::v-deep .el-message{
   top:80px !important;
}
#app {
  width: 100%;
  height: 100vh;
  font-family:element-icons;
}
#noScrollbar::-webkit-scrollbar {
  display: none !important;
}
body {
  transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  /* IE 9 */
  -webkit-transform: scale(1, 1);
  /* Safari and Chrome */
  transform-origin: left top;
  /*设置左上角为缩放原点*/
}
/* 垂直滚动条样式 */
/* 宽度 */
// ::-webkit-scrollbar {
//   width: 3px;
// }
/* 背景色 */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
/* 滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

/* 背景色 */
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
/* 滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
}

#app {
  background: #f5f5f5;
}
body {
  // width:10rem;
  // max-width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
  background-color: rgb(32, 33, 54);
}
* {
  margin: 0;
  padding: 0;
}
// div {
//   -webkit-user-select: none;
//   /*谷歌 /Chrome*/
//   -moz-user-select: none;
//   /*火狐/Firefox*/
//   -ms-user-select: none;
//   /*IE 10+*/
//   user-select: none;
// }
/* 在你的 CSS 文件中添加以下样式 */
#nprogress {
  /* 进度条背景颜色 */
  background: #e4e4e4 !important;
}
#nprogress .bar {
  /* 进度条颜色 */
  background: #3f8cff !important;
  height: 2px !important;
}
body {
  min-width: 1200px;
  margin: 0 auto !important;
  background-color: #f5f5f5;
}

.animated-message {
  position: fixed;
  /* 使用 fixed 定位可以让弹框相对于视口居中 */
  top: 35px;
  /* 垂直居中，位于视口顶部的50%位置 */
  left: 50%;
  /* 水平居中，位于视口左侧的50%位置 */
  transform: translate(-50%, -50%);
  /* 使用 transform 来微调位置，以使元素完全居中 */
  height: 35px;
  border-radius: 10px;
  background-color: rgb(119 119 119 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 5px 15px;
  z-index: 9999;
  font-size: 16px;
}

.myDialog_but {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 20px;
  height: 40px;
  padding-top: 10px;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid #f5f5f5;
  width: 100%;
  text-align: right;
  position: relative;
  right: 0;

  & > :nth-child(1) {
    margin: 20px;
    line-height: 65px;
    position: absolute;
    right: 120px;
  }

  & > :nth-child(2) {
    margin: 20px;
    line-height: 65px;
    position: absolute;
    right: 0px;
  }
}

.bottom_but {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  height: 60px;
  flex-flow: row-reverse;

  & > :nth-child(1) {
    margin: 20px;
    line-height: 65px;
  }
}
.cursor {
  cursor: pointer;
}
.fx_al {
  display: flex;
  align-items: center;
}
.ellipsis {
  overflow: hidden; /* 确保超出容器的文本被裁剪 */
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 在文本超出容器宽度时显示省略号 */
}

.nocopy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

</style>
