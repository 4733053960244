import http from './http';
let url = '/api/adminApi/admin'
export default {
    // 权限添加
    purviewCreate(data) {
        return http.post(url + '/permission/create', data);
    },
    // 菜单层级列表
    purviewList(data) {
        return http.get(url + '/permission/sList');
    },
    // 菜单左侧列表
    getpurviewlst(data) {
        return http.get(url + '/permission/lst');
    },
};
