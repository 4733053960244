import { gsap } from 'gsap';
import Vue from 'vue'
export default {
    // targetValue：最终数字   onUpdateCallback：回调函数
    animateValueTo(targetValue, onUpdateCallback) {
        const valueObj = { value: 0 };
        gsap.to(valueObj, {
            value: targetValue,
            duration: 1,
            onUpdate: () => {
                return onUpdateCallback(Math.floor(valueObj.value));
            },
        });
    },
    // 地址根据value找到label
    findLabels(data, targetValue, parentLabel = null) {
        for (const node of data) {
          if (node.value === targetValue) {
            return { currentLabel: node.label, parentLabel: parentLabel };
          }
          if (node.children) {
            const result = findLabels(node.children, targetValue, node.label);
            if (result) {
              return result;
            }
          }
        }
        return null;
    },
    // 防抖
    debounce(func, wait, el) {
        let timer;
        return function () {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {

                func.apply(el, arguments);
            }, wait);
        };
    },
    submit_debounce(func, wait = 2000) {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            const callNow = !timeout;
            timeout = setTimeout(() => {
                timeout = null;
            }, wait);
            if (callNow) func.apply(context, args);
        };
    },
    //    检测是不是网络图片
    isHttp(str) {
        if (str.toLowerCase().startsWith("http")) {
            return true;
        } else {
            return false
        }
    },
    // 节流
    throttle(func, wait) {
        let timeoutId;
        return function (...args) {
            if (!timeoutId) {
                // 如果计时器不存在，则执行函数
                func(...args);
                // 设置计时器，在指定时间后重置为null，允许下一次执行
                timeoutId = setTimeout(() => {
                    timeoutId = null;
                }, wait);
            }
        };
    },
    // 深拷贝
    Copy(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        if (Array.isArray(obj)) {
            return obj.map(Copy);
        }
        const copy = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                copy[key] = Copy(obj[key]);
            }
        }
        return copy;
    },
    // base64 生成file文件
    base64toFile(data, name = 'setting') {
        const fileName = `${new Date().getTime()}`;
        const dataArr = data.split(",");
        const byteString = atob(dataArr[1]);
        const options = {
            type: "image/jpeg",
            endings: "native",
        };
        const u8Arr = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            u8Arr[i] = byteString.charCodeAt(i);
        }
        let formData = new FormData();
        formData.append("file", new File([u8Arr], fileName + ".jpg", options));
        formData.append("name", name);
        
        console.log('name:', name)
        return formData;
    },
    // 上传图片
    uploadImg(file) {
        return Vue.prototype.$api.setting.uploadImg(file)
    },
    // query 参数解密
    secretKey(data) {
        const decryptedData = Vue.prototype.$crypto.AES.decrypt(data, 'YourSecretKey').toString(Vue.prototype.$crypto.enc.Utf8);
        return decryptedData
    },
    // 子组件获取权限
    getgetmodule(list, label, Navlist) {
        const nav = list
            .find((res) => res.label === label)
            ?.children.map((item) => item.label) || [];
        Navlist = Navlist.filter(item => nav.includes(item.title));
        return Navlist

    }
}

