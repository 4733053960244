import http from './http';
let url = '/api/adminApi'
// 贴子相关
export default {
    // 创建贴子图片列表
    communityCreate(data) {
        return http.post(url + '/admin/anImg/create', data);
    },
    // 创建贴子图片列表 deleteItem
    communityImgList(data) {
        return http.get(url + '/admin/anImg/lst?page=' + data.page + '&limit=' + data.limit);
    },
    // 删除图
    deleteItem(data) {
        return http.get(url + '/admin/anImg/del?id=' + data);
    },
    // 创建分类
    classificationCreate(data) {
        return http.post(url + '/admin/anCate/create', data);
    },
    // 分类列表
    gettableclassification(data) {
        return http.get(url + '/admin/anCate/lst', data);
    },
    // 分类删除
    delclassification(data) {
        return http.get(url + '/admin/anCate/del?id=' + data);
    },
    // 分类修改
    updateclassification(data) {
        return http.post(url + '/admin/anCate/update', data);
    },
    // 分类排序
    sortclassification(data) {
        return http.post(url + '/admin/anCate/sort', data);
    },
    // 贴子创建
    TiebaCreate(data) {
        return http.post(url + '/admin/an/create', data);
    },
    // 贴子创建
    TiebaUp(data) {
        return http.post(url + '/admin/an/update', data);
    },
    // 获取封面
    get_an_cover(data) {
        return http.get(url + '/tool/get_an_cover');
    },
    // 获取标签
    get_an_cate(data) {
        return http.get(url + '/tool/get_an_cate');
    },
    // 帖子列表
    get_an_lst(data) {
        return http.get(url + '/admin/an/lst?page=' + data.page +'&status='+data.status+'&name='+data.searchValue+ '&limit=' + data.limit ?? 10);
    },
    // 帖子详情
    get_an_details(data) {
        return http.get(url + '/admin/an/info?id=' + data);
    },
    // 帖子删除
    del(data){
        return http.get(url + '/admin/an/del?id=' + data);
    }
};
