import axios from 'axios';
import loading from '../utils/loading';
import Vue from 'vue';
import url from './requestUrl';

const instance = axios.create({
    baseURL: url,
    timeout: 10000, // 设置请求超时时间,
});
const { isCancel } = axios;
const cacheRequest = {};
// 删除缓存队列中的请求
function removeCacheRequest(reqKey) {
    if (cacheRequest[reqKey]) {
        cacheRequest[reqKey].abort();
        delete cacheRequest[reqKey];
    }
}
instance.interceptors.request.use(config => {
    const { url, removeCache = false } = config;
    if (removeCache) {
        removeCacheRequest(url);
        const controller = new AbortController();
        config.signal = controller.signal;
        cacheRequest[url] = controller;
    }
    if (config.url !== '/api/adminApi/login') {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = token;
        }
    }
    return config;
}, err => {
    return Promise.reject(err);
});

instance.interceptors.response.use(response => {
    const { url, method, removeCache = false } = response.config;
    if (removeCache) removeCacheRequest(`${url}&${method}`);
    loading.methods.hideLoading();
    return response;
}, err => {
    loading.methods.hideLoading();
    if (isCancel(err)) {
        // 取消请求时不显示错误信息
        return new Promise(() => {}); // 返回一个未决的 Promise
    } else {
        Vue.prototype.$message.error(err.response.data.msg ? err.response.data.msg : '服务器错误!');
        return Promise.reject(err.response.data);
    }
});

export default instance;
