import Vue from 'vue'
import VueRouter from 'vue-router'
import { gsap } from 'gsap';
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import LoadingMixin from '../utils/loading'; // loading 

Vue.use(VueRouter, nprogress)
const RouetName = '-伊企学管理后台'
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login/index'),
    meta: {
      title: '系统登录' + RouetName,
      transitionName: 'fade'
    }
  },
  {
    path: '',
    component: () => import('../pages/layout/index'),
    children: [
      {
        path: '/',
        name: '/',
        component: () => import('../pages/home/index.vue'),
        meta: {
          title: '首页' + RouetName,
          name: '数据管理',
          transitionName: 'fade'
        },

      },
      {
        path: '/gsap',
        name: '/gsap',
        component: () => import('../pages/home/gasp.vue'),
        meta: {
          title: '首页',
          name: '课程管理',
          transitionName: 'fade'
        },

      },
      {
        path: '/course',
        name: '/course',
        component: () => import('../pages/course/index.vue'),
        meta: {
          title: '课程管理',
          name: '课程管理',
          transitionName: 'fade'
        },

      },
      {
        path: '/growUp',
        name: '/growUp',
        component: () => import('../pages/course/components/growUp.vue'),
        meta: {
          title: '成长课程' + RouetName,
          name: '成长课程',
          transitionName: 'fade'
        },

      },
      {
        path: '/curriculum',
        name: '/curriculum',
        component: () => import('../pages/course/components/curriculum.vue'),
        meta: {
          title: '课程预告' + RouetName,
          name: '课程预告',
          transitionName: 'fade'
        },

      },
      {
        path: '/select',
        name: '/select',
        component: () => import('../pages/course/components/picked.vue'),
        meta: {
          title: '课程管理' + RouetName,
          name: '精选课程',
          transitionName: 'fade'
        },

      },
      {
        path: '/intensiveTrain',
        name: '/intensiveTrain',
        component: () => import('../pages/course/components/intensiveTrain.vue'),
        meta: {
          title: '集训营' + RouetName,
          name: '集训营',
          transitionName: 'fade'
        },

      },
      {
        path: '/videoList',
        name: '/videoList',
        component: () => import('../pages/course/components/videoList.vue'),
        meta: {
          title: '视频库' + RouetName,
          name: '视频',
          transitionName: 'fade'
        },

      },
      {
        path: '/lecturer',
        name: '/lecturer',
        component: () => import('../pages/course/components/lecturer.vue'),
        meta: {
          title: '讲师列表' + RouetName,
          name: '讲师列表',
          transitionName: 'fade'
        },

      },
      {
        path: '/reservation',
        name: '/reservation',
        component: () => import('../pages/course/components/reservation.vue'),
        meta: {
          title: '讲师管理-预约列表',
          name: '预约列表',
          transitionName: 'fade'
        },

      },
      {
        path: '/setting',
        name: '/setting',
        component: () => import('../pages/setting/index.vue'),
        meta: {
          title: '系统设置' + RouetName,
          name: '系统设置',
          transitionName: 'fade'
        },
      },
      {
        path: '/information',
        name: '/information',
        component: () => import('../pages/setting/components/foundation/index.vue'),
        meta: {
          title: '基础信息',
          name: '基础信息',
          transitionName: 'fade'
        },
      },
      {
        path: '/PageConfiguration',
        name: '/PageConfiguration',
        component: () => import('../pages/setting/components/PageConfiguration/index.vue'),
        meta: {
          title: '页面配置' + RouetName,
          name: '页面配置',
          transitionName: 'fade'
        },
      },
      {
        path: '/grade',
        name: '/grade',
        component: () => import('../pages/setting/components/grade/index.vue'),
        meta: {
          title: '成长等级' + RouetName,
          name: '成长等级',
          transitionName: 'fade'
        },
      },
      {
        path: '/LecturerLv',
        name: '/LecturerLv',
        component: () => import('../pages/setting/components/lecturer/index.vue'),
        meta: {
          title: '讲师等级' + RouetName,
          name: '讲师等级',
          transitionName: 'fade'
        },
      },
      {
        path: '/Carousel',
        name: '/Carousel',
        component: () => import('../pages/setting/components/Carousel/index.vue'),
        meta: {
          title: 'Banner设置' + RouetName,
          name: 'Banner设置',
          transitionName: 'fade'
        },
      },
      {
        path: '/growUpBanner',
        name: '/growUpBanner',
        component: () => import('../pages/setting/components/growupBanner/index.vue'),
        meta: {
          title: '成长中心banner',
          name: '成长中心banner',
          transitionName: 'fade'
        },
      },
      {
        path: '/manage',
        name: '/manage',
        component: () => import('../pages/manage/index.vue'),
        meta: {
          title: '企业管理',
          name: '组织架构',
          transitionName: 'fade'
        },
      },
      {
        path: '/KJLaccountNmber',
        name: '/KJLaccountNmber',
        component: () => import('../pages/manage/components/foundation/KJLaccountNmber.vue'),
        meta: {
          title: '酷家乐账号管理' + RouetName,
          name: '酷家乐账号管理',
          transitionName: 'fade'
        },
      },
      {
        path: '/account',
        name: '/account',
        component: () => import('../pages/manage/components/foundation/index.vue'),
        meta: {
          title: '企业管理' + RouetName,
          name: '组织架构',
          transitionName: 'fade'
        },
      },
      {
        path: '/role',
        name: '/role',
        component: () => import('../pages/manage/components/PageConfiguration/index.vue'),
        meta: {
          title: '角色权限' + RouetName,
          name: '角色权限',
          transitionName: 'fade'
        },
      },
      {
        path: '/NewCourse',
        name: '/NewCourse',
        component: () => import('../pages/course/NewCourse/index.vue'),
        meta: {
          title: '精选课程' + RouetName,
          name: '精选课程',
          type: '/course',
          transitionName: 'fade'
        },
      },
      {
        path: '/newgrowUp',
        name: '/newgrowUp',
        component: () => import('../pages/course/NewCourse/growUp.vue'),
        meta: {
          title: '成长课程' + RouetName,
          name: '成长课程',
          type: '/course',
          transitionName: 'fade'

        },
      },
      {
        path: '/newintensiveTrain',
        name: '/newintensiveTrain',
        component: () => import('../pages/course/NewCourse/intensiveTrain.vue'),
        meta: {
          title: '集训营' + RouetName,
          name: '集训营',
          type: '/course',
          transitionName: 'fade'

        },
      },
      {
        path: '/purview',
        name: '/purview',
        component: () => import('../pages/purview/index.vue'),
        meta: {
          title: '权限管理',
          name: '路由配置',
          transitionName: 'fade'


        },
      },
      {
        path: '/exam',
        name: '/exam',
        component: () => import('../pages/exam/index.vue'),
        meta: {
          title: '考试管理' + RouetName,
          name: '考试管理',
          transitionName: 'fade'


        },
      },
      {
        path: '/newaxam',
        name: '/newaxam',
        component: () => import('../pages/exam/components/newaxam.vue'),
        meta: {
          title: '考试管理' + RouetName,
          name: '新建/编辑试卷',
          type: '/exam',
          transitionName: 'fade'

        },
      },
      {
        path: '/newquestion',
        name: '/newquestion',
        component: () => import('../pages/exam/components/newquestion.vue'),
        meta: {
          title: '题库管理' + RouetName,
          name: '题库管理',
          type: '/newquestion',
          transitionName: 'fade'

        },
      },
      {
        path: '/programme',
        name: '/programme',
        component: () => import('../pages/programme/index.vue'),
        meta: {
          title: '方案管理' + RouetName,
          name: '方案管理',
          type: '/programme',
          transitionName: 'fade'

        },
      },
      {
        path: '/programmeCheck',
        component: () => import('../pages/programme/components/programmeCheck.vue'),
        meta: {
          title: '优秀方案审核' + RouetName,
          transitionName: 'fade'
        }
      },
      {
        path: '/addScheme',
        name: '/addScheme',
        component: () => import('../pages/programme/components/addScheme.vue'),
        meta: {
          title: '方案管理',
          name: '方案管理',
          type: '/programme',
          transitionName: 'fade'

        },
      },
      {
        path: '/addSpecial',
        name: '/addSpecial',
        component: () => import('../pages/programme/components/addSpecial.vue'),
        meta: {
          title: '方案管理' + RouetName,
          name: '方案专题',
          type: '/programme',
          transitionName: 'fade'

        },
      },
      {
        path: '/Scenery',
        name: '/Scenery',
        component: () => import('../pages/Scenery/index.vue'),
        meta: {
          title: '实景图管理' + RouetName,
          name: '实景图管理',
          type: '/Scenery',
          transitionName: 'fade'

        },
      },
      {
        path: '/addScenery',
        name: '/addScenery',
        component: () => import('../pages/Scenery/components/addSpecial.vue'),
        meta: {
          title: '实景图管理' + RouetName,
          name: '实景图管理',
          type: '/Scenery',
          transitionName: 'fade'

        },
      },
      {
        path: '/addScenery',
        name: '/addSpecials',
        component: () => import('../pages/Scenery/components/addSpecial.vue'),
        meta: {
          title: '实景管理',
          name: '新建实景',
          type: '/Scenery',
          transitionName: 'fade'

        },
      },
      {
        path: '/tagManage',
        name: '/tagManage',
        component: () => import('../pages/Scenery/components/tagManage.vue'),
        meta: {
          title: '标签管理' + RouetName,
          name: '标签管理',
          type: '/Scenery',
          transitionName: 'fade'

        },
      },
      {
        path: '/community',
        name: '/community',
        component: () => import('../pages/community/index.vue'),
        meta: {
          title: '社区管理' + RouetName,
          name: '社区管理',
          type: '/community',
          transitionName: 'fade'

        },
      },
      {
        path: '/addCommunity',
        name: '/addCommunity',
        component: () => import('../pages/community/addCommunity.vue'),
        meta: {
          title: '社区管理' + RouetName,
          name: '社区管理',
          type: '/community',
          transitionName: 'fade'

        },
      },
      {
        path: '/VueUeditorWrap',
        name: '/VueUeditorWrap',
        // component: () => import('@/components/VueUeditorWrap.vue'),
        component: () => import('../components/components/UEditor.vue'),

        meta: {
          title: '标签管理' + RouetName,
          name: '标签管理',
          type: '/Scenery',
          transitionName: 'fade'

        },
      },
      {
        path: '/notice',
        name: '/notice',
        component: () => import('../pages/notice/index.vue'),
        meta: {
          title: '通知管理' + RouetName,
          name: '通知管理',
          type: '/notice',
          transitionName: 'fade'
        },
      },
      {
        path: '/shopping',
        name: '/shopping',
        component: () => import('../pages/shopping/index.vue'),
        meta: {
          title: '商品管理' + RouetName,
          name: '商品管理',
          type: '/notice',
          transitionName: 'fade'

        },
      },
      {
        path: '/shopping_Setting',
        name: '/shopping_Setting',
        component: () => import('../pages/shopping/shopping_Setting.vue'),
        meta: {
          title: '商品配置' + RouetName,
          name: '商品配置',
          type: '/notice',
          transitionName: 'fade'

        },
      },
      {
        path: '/record',
        name: '/record',
        component: () => import('../pages/shopping/record.vue'),
        meta: {
          title: '兑换列表' + RouetName,
          name: '兑换列表',
          type: '/notice',
          transitionName: 'fade'

        },
      },
      {
        path: '/comment',
        name: '/comment',
        component: () => import('../pages/comment/index.vue'),
        meta: {
          title: '评论管理' + RouetName,
          name: '评论管理',
          type: '/comment',
          transitionName: 'fade'
        },
      },
      {
        path: '/sensitive',
        name: '/sensitive',
        component: () => import('../pages/comment/sensitive.vue'),
        meta: {
          title: '敏感词管理' + RouetName,
          name: '敏感词管理',
          type: '/sensitive',
          transitionName: 'fade'
        },
      },
      {
        path: '/product',
        name: '/product',
        component: () => import('../pages/product/index.vue'),
        meta: {
          title: '产品中心' + RouetName,
          name: '产品中心',
          type: '/product',
          transitionName: 'fade'
        },
      },
      {
        path: '/newproduct',
        name: '/newproduct',
        component: () => import('../pages/product/newproduct.vue'),
        meta: {
          title: '产品中心' + RouetName,
          name: '新建产品',
          type: '/product',
          transitionName: 'fade'
        },
      },
      {
        path: '/newSpecial',
        name: '/newSpecial',
        component: () => import('../pages/product/newSpecial.vue'),
        meta: {
          title: '产品中心' + RouetName,
          name: '新建专题',
          type: '/product',
          transitionName: 'fade'
        },
      },
      {
        path: '/createDesign',
        name: '/createDesign',
        component: () => import('../pages/design/programme/createDesign.vue'),
        meta: {
          title: '设计大赛' + RouetName,
          name: '设计大赛-新建设计大赛',
          type: '/createDesign',
          transitionName: 'fade'
        },
      },
     
      {
        path: '/design',
        name: '/design',
        component: () => import('../pages/design/indexs.vue'),
        meta: {
          title: '设计大赛' + RouetName,
          name: '设计大赛',
          type: '/design',
          transitionName: 'fade'
        },
      },
      {
        path: '/works',
        name: '/works',
        component: () => import('../pages/design/programme/works.vue'),
        meta: {
          title: '设计大赛' + RouetName,
          name: '设计大赛-参赛作品管理',
          type: '/works',
          transitionName: 'fade'
        },
      },
      {
        path: '/ScoringDetails',
        name: '/ScoringDetails',
        component: () => import('../pages/design/programme/ScoringDetails.vue'),
        meta: {
          title: '设计大赛' + RouetName,
          name: '设计大赛-参赛作品管理',
          type: '/ScoringDetails',
          transitionName: 'fade'
        },
      },
      {
        path: '/createLiveAction',
        name: '/createLiveAction',
        component: () => import('../pages/design/liveAction/createDesign.vue'),
        meta: {
          title: '设计大赛' + RouetName,
          name: '设计大赛-新建设计大赛',
          type: '/createLiveAction',
          transitionName: 'fade'
        },
      },
      {
        path: '/liveActionworks',
        name: '/liveActionworks',
        component: () => import('../pages/design/liveAction/works.vue'),
        meta: {
          title: '设计大赛' + RouetName,
          name: '设计大赛-参赛作品管理',
          type: '/liveActionworks',
          transitionName: 'fade'
        },
      },

    ]
  },
  {
    path: '/Preview',
    name: '/Preview',
    component: () => import('../pages/design/programme/Preview.vue'),
    meta: {
      title: '设计大赛预览' + RouetName,
      name: '设计大赛-新建设计大赛',
      type: '/Preview',
      transitionName: 'fade'
    },
  },
  {
    path: '*',
    name: 'error',
    component: () => import('../pages/err.vue'),
    meta: {
      title: '404',
      transitionName: 'fade'
    }
  },

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  nprogress.start();
  document.title = to.meta.title
  if (to.path == '/NewCourse' && from.path == '/course') {
    to.meta.name = '课程管理/新建集训营'
    to.meta.title = '新建集训营'
  }
  const token = sessionStorage.getItem('token');
  if (to.path === '/login' && token) {
    next('/');
  } else if (to.path === '/login') {
    next();
  } else {
    if (!token) {
      next('/login');
    } else {
      next();

    }
  }
});
router.afterEach(to => {

  // LoadingMixin.methods.hideLoading()
  nprogress.done();
  window.scrollTo(0, 0);
});
export default router
