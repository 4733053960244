// mixins/loading.js

import { Loading } from 'element-ui';

export default {
  data() {
    return {
      loadingInstance: null,
    };
  },
  methods: {
    showLoading(type, customTarget, content) {
      const target = customTarget ? customTarget : '.View-content';
      this.loadingInstance = Loading.service({
        fullscreen: false,
        lock: true,
        text: content ? content : 'Loading...',
        background: type ? 'rgba(0, 0, 0,0.6)' : 'rgba(225, 225, 225,)',
        target: target,
      });
    },
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();

      }
    },
  },
};
