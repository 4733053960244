const roleData = {
    state: {
        data: {
            id: 0,    // 角色id
            userIdArr: [] //用户id
        }
    },
    getters: {
    
    },
    mutations: {
        // 修改添加管理员参数
        roleSetrole(state, data) {
            state.data = {...state.data, ...data}
        }
    },

    actions: {

    }
}

export default roleData;
