// 设置
import api from '@/http/setting'

const state = {
    data: {
        // 可以添加一些初始状态
    },
    configlist: [],
    // 设置 =》基础信息
    basisObj:{}
};

const mutations = {
    // 基础信息全部数据
    SetbasisObj(state,data){
        state.basisObj = {...state.basisObj,...data}
    }
};

const actions = {
    // 设置页面获取配置
    async getconfig({ state }, data) {
        try {
            const res = await api.settingConfig(data);

            // 处理返回的配置数据，可以调用 mutations 中的方法修改 state
            if (res.data.code == 200) {
               
                state.configlist = res.data.data
                return res.data
            }

        } catch (error) {
            console.error('请求失败', error);
        }
    }
};

export default {
    namespaced: true, // 启用命名空间
    state,
    mutations,
    actions
};
