import Vue from 'vue'
Vue.directive('flex', {
  bind: function (el) {
    el.style.display = 'flex'
    el.style.alignItems = 'center'
    el.style.justifyContent = 'center'

  }
})
Vue.directive('Height', {
  bind: function (el, binding) {
   
    el.style.height =  'calc(100vh - 70px)'
    el.style.overflowY = 'auto'
    el.style.background = binding.value ? binding.value : '#ffff';
    el.setAttribute('id', 'noScrollbar'); 
  }
})
Vue.directive('bg', {
  bind(el, binding) {
    el.style.background = binding.value;
  }
});

Vue.directive('app', {
  bind(el, binding) {
    el.style.height = 'calc(100vh - 60px)'
    el.style.background = '#ffff';
    el.style.overflowY = 'auto';

    if (binding.value) {
      el.style.padding = `15px ${binding.value}px 0px ${binding.value}px`;
    }
  }
});

Vue.directive('but', {
  bind(el, binding) {
    let { w, h, c } = binding.value   // 宽。高。颜色
    el.style.width = w + 'px';
    el.style.height = h + 'px';
    el.style.cursor = 'pointer'
    el.style.color = c;
    if (binding.value.r) {
      el.style.borderRadius = binding.value.r + 'px'
    }
  }
});