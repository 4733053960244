<template>
    <div>
        <el-dialog :title="title" :visible.sync="Switch" :width="Getwidth" :before-close="handleClose">
            <div class="content">
                <div>
                    <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
                </div>
                <div>
                    <div v-for="(item, index) in 4" :key="index">
                        <div class="right_item">
                            <div style="margin: 0 21px;"> <el-checkbox class="circle-checkbox"
                                    v-model="checked"></el-checkbox></div>

                            <div v-flex :style="{ borderBottom: '1px solid #f5f5f5', height: ' 100%' }">
                                <div>
                                    <img src="https://img2.baidu.com/it/u=2078785911,1809964779&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1693760400&t=204365a80859e467a717c4dcaae9305b"
                                        alt="" style="">
                                </div>
                                <div class="right_item_text"> 【第二十二讲】CRM系统木门下单操作【第二十二讲】CRM系统木门下单操作【第二十二讲】CRM系统木门下单操作</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="myDialog_but">
                <div v-but="{ w: 100, h: 36, c: '#8C8FA7', r: 8 }" v-bg="'#f5f5f5'" v-flex>恢复默认</div>
                <div v-but="{ w: 100, h: 36, c: '#ffff', r: 8 }" v-bg="'#478bff'" v-flex>保存</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'StudyClassifyDialog',
    props: {
        title: {
            type: String,
            required: true,
        },
        Switch:{
            type: [Boolean,String],
            required: false,
        }
    },
    data() {
        return {
            dialogVisible: false,
            checked: false,
            data: [
                {
                    label: '成长中心',
                    children: [{
                        label: '精选课程',

                    }]
                },
                {
                    label: '成长中心',
                    children: [{
                        label: '精选课程',

                    }]
                }, {
                    label: '成长中心',
                    children: [{
                        label: '精选课程',

                    }]
                },
            ],
            defaultProps: {
                children: 'children',
                label: 'label'
            }
        };

    },

    mounted() {
    },
    computed: {
        Getwidth() {
            let width = window.innerWidth
            if(width>1600)return '50%'
            else return '70%'
        }
    },
    methods: {
        handleClose() {
            done();
           this.$emit('Event',false)
        },
        handleNodeClick(data) {
        }

    },
};
</script>

<style lang="scss" scoped>
/* 假设你的项目使用CSS样式表 */
::v-deep .circle-checkbox .el-checkbox__input {
    border-radius: 50%;
    /* 设置圆角为50%，将复选框变成圆形 */
}

/* 或者如果你的项目使用SCSS，你可以这样写 */
::v-deep.circle-checkbox {
    .el-checkbox__input {
        border-radius: 50%;
        /* 设置圆角为50%，将复选框变成圆形 */
    }
}

img {
    width: calc(120px * 16) rem;
    height: 68px;
    border-radius: 8px;
}

.content {
    width: 100%;
    height: 400px;
    display: flex;

    &>:first-child {
        width: 20%;
        // background-color: red;
        border-right: 1px solid #f5f5f5;
    }

    &>:last-child {
        flex: 1;
        // background-color: pink;
        height: 100%;
        overflow-y: auto;

    }
}

.right_item {
    display: flex;
    align-items: center;
    height: 120px;
    margin-bottom: 10px;
    // background-color: pink;

    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #100F48;

    &_text {
        margin-left: 39px;
        width: 300px;
        overflow: hidden;
        /* 隐藏溢出的文本 */
        text-overflow: ellipsis;
        /* 使用省略号表示溢出的文本 */
        display: -webkit-box;
        /* 设置为弹性布局 */
        -webkit-line-clamp: 2;
        /* 最多显示两行文本 */
        -webkit-box-orient: vertical;
        /* 垂直排列文本 */
    }

}


</style>