// 课程类
import http from './http';
let url = '/api/adminApi/admin'
export default {
  // 添加分类
  createCate(data) {
    return http.post(url + '/course/createCate', data);
  },
  // 获取父级分类
  getParentCate(data) {
    return http.get(url + '/course/getParentCate');
  },
   // 获取父级分类
   getParentCates(data) {
    return http.get(  '/api/adminApi/tool/getParentCate');
  },
  // 分类列表
  cateLst(data) {
    if(data.page && !data.limit){
      return http.get(url + '/course/cateLst?parentId=' + data.parentId + '&name=' + data.name + '&page=' + data.page + '&limit=' +10);
    }else if(data.page && data.limit){
      return http.get(url + '/course/cateLst?parentId=' + data.parentId + '&name=' + data.name + '&page=' + data.page + '&limit=' + data.limit);

    }
  
    return http.get(url + '/course/cateLst?parentId=' + data.parentId + '&name=' + data.name);
  },
  // 分类删除
  delCate(data) {
    return http.get(url + '/course/delCate?id=' + data.id);
  },
  // 编辑
  updateCate(data) {
    return http.post(url + '/course/updateCate', data);
  },
  // 新建课程  分类列表
  courseCateLst(data) {
    return http.get('/api/adminApi/tool/courseCateLst');
  }, // 创建课程 
  Curriculum(data) {
    return http.post(url + '/course/create', data);
  },
  // 课程列表 
  selectlst(data) {
    return http.get(url + `/course/selectlst?searchValue=${data.searchValue}&limit=${data.limit}&page=${data.page}`);
  },
  // 课程置顶
  settingtop(data) {
    return http.get(url + `/course/top?id=${data.id}&top=${data.top}`);
  },
  // 课程删除
  selectdel(data) {
    return http.get(url + `/course/del?id=${data.id}`);
  },
  // 课程详情
  selectinfo(data) {
    return http.get(url + `/course/info?id=${data}`);
  },
  // 课程编辑
  selectupdate(data) {
    return http.post(url + `/course/update`, data);
  },
  // *************  集训营
  // 获取课程列表
  couseLst(data) {
    return http.get(`/api/adminApi/tool/couseLst`);
  },
  // 获取课程列表
  paper_couse_lst(data) {
    return http.get(`/api/adminApi/tool/paper_couse_lst?id=` + data || 0);
  },
  // 创建集训营
  create(data) {
    return http.post(`/api/adminApi/admin/camp/create`, data);
  },
  // 集训营列表
  lst(data) {
    return http.get(`/api/adminApi/admin/camp/lst?searchValue=${data.searchValue}&limit=${data.limit}&page=${data.page}`);
  },
  // 删除
  del(data) {
    return http.get(`/api/adminApi/admin/camp/del?id=` + data);
  },
  // 详情
  czinfo(data) {
    return http.get(`/api/adminApi/admin/camp/info?id=` + data);
  },
  // 编辑提交
  update(data) {
    return http.post(`/api/adminApi/admin/camp/update`, data);
  },
  // 创建集训营课程
  createCourse(data) {
    return http.post(`/api/adminApi/tool/createCourse`, data);
  },
  // 编辑集训营课程
  updateCourse(data) {
    return http.post(`/api/adminApi/tool/updateCourse`, data);
  },
  // 删除集训营课程
  delCourse(data) {
    return http.get(`/api/adminApi/tool/delCourse?id=` + data);
  },
  // 集训营课程详情
  campCourseInfo(data) {
    return http.post(`/api/adminApi/tool/campCourseInfo`, data);
  },

};
