import http from './http';
let url = '/api/adminApi/admin'
export default {
  // 获取配置
  settingConfig(data = { type: 1 }) {
    return http.get(url + '/config/lst?type=' + data.type);
  },
  //  设置开关状态
  settingStatus(data) {
    return http.post(url + '/config/set', data);
  },
  // 基础配置新增/修改
  settingCreate(data) {
    return http.post(url + '/config/saveInformation', data);
  },
  // 上传图片
  uploadImg(data) {
    // 基础配置:setting 首页banner:banner 成长等级:growthLevel 讲师等级:lecturerLevel
    return http.post('/api/adminApi/uploadImg', data);
  },
  // 成长等级
  growthLevel(data) {
    return http.post('/api/adminApi/admin/config/growthLevel', data);
  },
  // 成长等级删除
  growthDel(data) {
    return http.get('/api/adminApi/admin/config/growthDel?id=' + data);
  },
  // 讲师等级
  lecturerLevel(data) {
    return http.post('/api/adminApi/admin/config/lecturerLevel', data);
  },
  // 讲师等级删除
  lecturerDel(data) {
    return http.get('/api/adminApi/admin/config/lecturerDel?id=' + data);
  },
  // 首页banner
  banner(data) {
    return http.post('/api/adminApi/admin/config/banner', data);
  },
  // 成长banner
  addGrowthBanner(data) {
    return http.post('/api/adminApi/admin/config/addGrowthBanner', data);
  },
  // 删除banner
  delbanner(data) {
    return http.get('/api/adminApi/admin/config/bannerDel?id=' + data);
  },
  // 删除成长banner
  growthBannerDel(data) {
    return http.get('/api/adminApi/admin/config/growthBannerDel?id=' + data);
  },
  // 获取跳转链接id
  pageUrl(data) {
    return http.get('/api/adminApi/pageUrl?type=' + data);
  },
  // 页面配置  
  navigationLst(data) {
    return http.get('/api/adminApi/admin/config/navigationLst');
  },
  // 页面配置  模块列表
  pcIndexLst(data) {
    return http.get('/api/adminApi/admin/config/pcIndexLst');
  },
  // 页面配置  导航栏设置
  setNavigationLst(data) {
    return http.post('/api/adminApi/admin/config/setNavigationLst', data);
  },
   // 页面配置  首页板块设置
  setPcIndexLst(data) {
    return http.post('/api/adminApi/admin/config/setPcIndexLst', data);
  },
};
