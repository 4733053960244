import http from './http';
import AbortController from './AbortController';

let url = '/api/adminApi'
export default {
    // 成长等级列表
    czcourseCateLst(data) {
        return http.get(url + '/tool/czcourseCateLst', data);
    },
    // 成长等级创建
    czcreate(data) {
        return http.post(url + '/admin/course/czcreate', data);
    },
    // 成长等级修改
    czupdate(data) {
        return http.post(url + '/admin/course/czupdate', data);
    },
    selectlst(data) {
        return http.get(url + `/admin/course/czselectlst?searchValue=${data.searchValue}&limit=${data.limit}&page=${data.page}&cateId=${data.cateId}`);
    },
    // 详情
    czinfo(data) {
        return http.get(url + '/admin/course/czinfo?id=' + data);
    },
    // 置顶
    cztop(data) {
        return http.get(url + `/admin/course/cztop?id=${data.id}&top=${data.top}`);
    },
    // 课程删除
    selectdel(data) {
        return http.get(url + `/admin/course/czdel?id=${data.id}`);
    },
    // 课程表添加
    scheduleAdd(data) {
        return AbortController.post(url + `/admin/schedule/add`,data,{ removeCache: true });
    },
     // 课程表编辑
     scheduleupdate(data) {
        return AbortController.post(url + `/admin/schedule/update`,data,{ removeCache: true });
    },
     // 课程表列表
     schedulelst(data) {
        return http.get(url + `/admin/schedule/lst?limit=${data.limit}&page=${data.page}`);
    },
     // 课程表列表 删除
     scheduledel(data) {
        return http.get(url + `/admin/schedule/del?id=${data}`);
    },
};
