import http from './http';
let url = '/api/adminApi/admin'
export default {
    // 题库列表
    paperlst(data) {
        return http.get(url + `/examination/paper_lst?searchValue=${data.searchValue}&limit=${data.limit}&page=${data.page}`);
    },
    // 创建题库
    add_paper(data) {
        return http.get(url + '/examination/add_paper?name=' + data.name);
    },
    //题库删除
    paper_del(data) {
        return http.get(url + '/examination/paper_del?id=' + data);
    },
    //题库修改
    update_paper(data) {
        return http.post(url + '/examination/update_paper', data);
    },
    //试卷创建
    examCreate(data) {
        return http.post(url + '/examination/create', data);
    },

    //试卷列表
    examLst(data) {
        return http.get(url + `/examination/lst?searchValue=${data.searchValue}&limit=${data.limit}&page=${data.page}`);
    },
    //试卷删除
    examDel(data) {
        return http.get(url + '/examination/del?id=' + data);
    },
    //试卷上架下架
    examStatus(data) {
        return http.get(url + '/examination/update_status?id=' + data.id + '&status=' + data.status);
    },
    //试卷详情
    examinfo(data) {
        return http.get(url + '/examination/info?id=' + data);
    },
    //试卷导出
    examexcel(data) {
        return http.get(url + '/examination/excel_export?id=' + data);
    },
    //试卷修改
    examEdit(data) {
        return http.post(url + '/examination/update', data);
    },
    // 创建考试用的  题库列表
    questionBankList(data) {
        return http.get('/api/adminApi/tool/tkLst');
    },
    // 创建题目
    createQuestions(data) {
        return http.post(url + '/examination/create_questions', data);
    },
    // 题目详情
    questionsinfo(data) {
        return http.get(url + `/examination/questions_info?id=${data.id}&type=${data.type}`);
    },
    // 删除
    questionsDel(data) {
        return http.get(url + '/examination/questions_del?id=' + data);
    },
    // 修改
    update_questions(data) {
        return http.post(url + '/examination/update_questions', data);
    },

};
