// plugins/message.js
import { gsap } from 'gsap';

export default {
    install(Vue) {
        Vue.prototype.$msg = {
            success({ title }) {
                const container = document.createElement('div');
                container.className = 'animated-message';
                //  Element UI 图标元素
                const iconElement = document.createElement('i');
                iconElement.className = 'el-icon-loading';
                container.appendChild(iconElement);
                // 消息文本
                const titleElement = document.createElement('span');
                titleElement.textContent = title;
                container.appendChild(titleElement);
                iconElement.style.marginRight = '15px';
                document.body.appendChild(container);
                gsap.from(container, {
                    opacity: 0,
                    y: -20,
                    duration: 0.3,
                    ease: 'ease-in',
                    onComplete: () => {
                        setTimeout(() => {
                            gsap.to(container, {
                                opacity: 0,
                                y: -20,
                                duration: 0.3,
                                ease: 'power2.in',
                                onComplete: () => {
                                    container.remove();
                                },
                            });
                        }, 1500);
                    },
                });
            },
            error({ title }) {
                const container = document.createElement('div');
                container.className = 'animated-message';
                container.style.color='#ffff'
                //  Element UI 图标元素
                const iconElement = document.createElement('i');
                iconElement.className = 'el-icon-error';
                container.style.backgroundColor = 'red'
                container.appendChild(iconElement);
                // 消息文本
                const titleElement = document.createElement('span');
                titleElement.textContent = title +'!';
                container.appendChild(titleElement);
                iconElement.style.marginRight = '15px';
                document.body.appendChild(container);

                gsap.from(container, {
                    opacity: 0,
                    y: -20,
                    duration: 0.3,
                    ease: 'ease-in',
                    onComplete: () => {
                        setTimeout(() => {
                            gsap.to(container, {
                                opacity: 0,
                                y: -20,
                                duration: 0.3,
                                ease: 'power2.in',
                                onComplete: () => {
                                    container.remove();
                                },
                            });
                        }, 1500);
                    },
                });
            },
        };
    },
};

