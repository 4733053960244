import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import "./index.js"
import './utils/directive' //自定义指令
import LoadingMixin from './utils/loading'; // loading 
import apiPlugin from '@/http/api' // api
import MessagePlugin from './utils/message'; // 全局提示
import PageStyle from '../src/pages/components/PageStyle.vue';
import utils from './utils/index' //自定义指令
import MyDialog from '@/components/Dialog.vue'; //全局组件
import classifyDialog from '@/components/classifyDialog.vue'; //全局分类组件
import XEUtils from 'xe-utils';
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import "./index.js"
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
// import { Message } from 'view-design';
import VueLazyload from 'vue-lazyload'
import Editor from '@wangeditor/editor';
import JsonExcel from "vue-json-excel";
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import '@/assets/fonts/font.css'
Vue.use(VueAwesomeSwiper)
Vue.component('PageStyle', PageStyle)
Vue.component('JsonExcel', JsonExcel)
Vue.use(Editor);
Vue.use(VueLazyload, {
  preLoad: 1.3, //预加载的宽高比
  loading: require('../src/assets/1715326639911.jpg'), //图片加载状态下显示的图片
})
Vue.use(ViewUI);
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
});
Vue.config.errorHandler = function (err, vm, info) {
  // console.error('Vue Error:', err, info)
}

Vue.prototype.$crypto = CryptoJS; // 在Vue原型上注册CryptoJS，以便在组件中使用
import CryptoJS from 'crypto-js'; // 引入CryptoJS库
Vue.use(VXETable)
Vue.component('myDialog', MyDialog);
Vue.component('classifyDialog', classifyDialog);
Vue.use(MessagePlugin);
Vue.prototype.$utils = utils
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.mixin(LoadingMixin);
Vue.prototype.$width = window.innerWidth
Vue.use(apiPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
