

import http from './http';
import AbortController from './AbortController';
import setting from './setting';
import purview from './purview';
import curriculum from './curriculum';
import growup from './growup';
import lecturer from './lecturer';
import video from './video';
import publics from './public';
import questionBank from './questionBank';
import community from './community';
export default {
    install(Vue) {
        Vue.prototype.$api = {
            setting: setting,
            purview: purview,
            curriculum: curriculum,
            growup: growup,
            lecturer: lecturer,
            video: video,
            public: publics,
            questionBank: questionBank,
            community: community,
            // 登录 
            Userlogin(data) {
                return http.post('/api/adminApi/login', data)
            },
            // PC带id登录 
            pcLoginAdmin(data) {
                return http.post('/api/adminApi/pcLoginAdmin', data)
            },
            // 修改系统管理员密码 
            set_pwd(data) {
                return http.post('/api/adminApi/up_pwd', data)
            },
            // ---------------------企业管理--------------
            // 部门列表 
            departmentList(data) {
                return http.get('/api/adminApi/admin/dep/list')
            },
            // 部门列表 
            deplsts(data) {
                return http.get('/api/adminApi/tool/deplsts')
            },
            // 添加部门 
            addDepartment(data) {
                return http.post('/api/adminApi/admin/dep/created', data)
            },
            // 删除部门 
            delDepartment(data) {
                return http.post('/api/adminApi/admin/dep/del', data)
            },
            // 全部部门
            totalDivision(data) {
                return http.get('/api/adminApi/tool/deplst')
            },
            // 编辑部门 
            updateDepartment(data) {
                return http.post('/api/adminApi/admin/dep/update', data)
            },
            // 账号列表
            getTableData(data) {
                return http.post('/api/adminApi/admin/user/list', data)
            },
            // 移入成员账号列表
            roleUser(data) {
                return http.post('/api/adminApi/tool/roleUser', data)
            },
            // 添加账号
            addcreated(data) {
                return http.post('/api/adminApi/admin/user/created', data)
            },
            // 删除账号
            delcreated(data) {
                return http.get('/api/adminApi/admin/user/del?id=' + data)
            },
            // 添加 || 编辑 职位
            addPosition(data) {
                return http.post('/api/adminApi/tool/addEdit', data)
            },
            // 职位列表
            getPositionList(data) {
                return http.get('/api/adminApi/tool/positionLst')
            },
            // 职位删除
            delPosition(data) {
                return http.get('/api/adminApi/tool/positionDel/' + data)
            },
            // 编辑账号
            Editcreated(data) {
                return http.post('/api/adminApi/admin/user/update', data)
            },
            // 账号禁用启用
            accountStatus(data) {
                return http.get('/api/adminApi/admin/user/status?id=' + data.id + '&status=' + data.status)
            },
            // 密码修改
            resetPwd(data) {
                return http.get('/api/adminApi/admin/user/resetPwd?id=' + data)
            },
            // 角色权限列表
            quanxianList(data) {
                return http.get('/api/adminApi/admin/role/quanxianList')
            },
            // 角色添加
            quanxiancreate(data) {
                return http.post('/api/adminApi/admin/role/create', data)
            },
            // 角色修改
            roleUpdata(data) {
                return http.post('/api/adminApi/admin/role/roleUp', data)
            },
            // 角色列表
            rolelist() {
                return http.get('/api/adminApi/admin/role/lst')
            },
            // 角色删除
            roledel(data) {
                return http.get('/api/adminApi/admin/role/del?id=' + data)
            },
            // 角色编辑 => 权限list
            roleInfo(data) {
                return http.get('/api/adminApi/admin/role/roleInfo?id=' + data)
            },
            // 创建管理员
            createManager(data) {
                return http.post('/api/adminApi/admin/role/createManager', data)
            },
            // 管理员列表
            getuserList(data) {
                return http.get('/api/adminApi/admin/role/userList?id=' + data)
            },
            // 管理员删除
            delAdmin(data) {
                return http.get('/api/adminApi/admin/role/delManager?id=' + data)
            },
            // 获取凭证
            getVideoToken(data) {
                return http.post('/api/adminApi/tool/getVideoToken', data)
            },
            // 刷新凭证
            refreshUploadVideo(data) {
                return http.get('/api/adminApi/tool/refreshUploadVideo?videoId=' + data)
            },
            // 上传视频
            uploadVideo(data) {
                return http.post('/api/adminApi/admin/video/uploadVideo', data)
            },
            // 视频删除
            delVideo(data) {
                return http.get('/api/adminApi/admin/video/delVideo?id=' + data)
            },
            // 视频修改
            updateVideo(data) {
                return http.post('/api/adminApi/admin/video/update', data)
            },
            // 首页数据
            gethomeSum(data) {
                return http.get('/api/adminApi/admin/index/indexStatistics?dep_id=' + data.dep_id + '&level_id=' + data.level_id + '&pos_id=' + data.pos_id)
            },
            // 首页等级人数
            levelUserNum(data) {
                return http.get('/api/adminApi/admin/index/levelUserNum?dep_id=' + data.dep_id + '&level_id=' + data.level_id + '&pos_id=' + data.pos_id)
            },
            // 学习时长
            userRank(data) {
                return http.get('/api/adminApi/admin/index/userRank?dep_id=' + data.dep_id + '&level_id=' + data.level_id + '&pos_id=' + data.pos_id)
            },
            // 课程数据
            courseData(data) {
                return http.get('/api/adminApi/admin/index/courseData')
            },
            // 题库题数量
            tk_num(data) {
                return http.get('/api/adminApi/tool/tk_num?id=' + data)
            },
            // 试卷列表
            paper_lst(data) {
                return http.get('/api/adminApi/tool/paper_lst')
            },
            // 方案列表
            programme(data) {
                return http.post('/api/adminApi/admin/programme/lst', data)
            },
            // 方案置顶
            programme_top(data) {
                return http.get('/api/adminApi/admin/programme/programme_top?id=' + data.id + '&top=' + data.top)
            },
            // 方案删除
            programme_del(data) {
                return http.get('/api/adminApi/admin/programme/programme_del?id=' + data)
            },
            // 方案修改
            programme_updata(data) {
                return http.post('/api/adminApi/admin/programme/update', data)
            },
            // 方案分类列表
            programme_classify_list(data) {
                return http.get('/api/adminApi/admin/programme_cate/lst?limit=' + 10 + '&page=' + data.page)
            },
            // 方案分类列表
            fa_parent_lst(data) {
                return http.get('/api/adminApi/tool/fa_parent_lst')
            },
            // 方案标签删除
            programme_tag_del(data) {
                return http.get('/api/adminApi/admin/programme_cate/del?id=' + data)
            },
            // 标签创建
            programmeCreateCate(data) {
                return http.post('/api/adminApi/admin/programme_cate/create', data)
            },
            // 标签编辑
            programmeUpdateCate(data) {
                return http.post('/api/adminApi/admin/programme_cate/update', data)
            },
            // 根据方案id 获取方案标签列表
            getTagList(data) {
                if (data.type) {
                    return http.get('/api/adminApi/tool/fa_tag_lst?id=' + data.id + '&type=' + data.type)
                }
                return http.get('/api/adminApi/tool/fa_tag_lst?id=' + data)
            },
            // 获取方案封面 设置封面
            img_list(data) {
                return http.get('/api/adminApi/tool/img_list?id=' + data + '&num=8')
            },
            // 实景图列表
            liveActionList(data) {
                return http.get('/api/adminApi/admin/realistic/lst?limit=' + data.limit + '&page=' + data.page + '&searchValue=' + data.searchValue)
            },
            // 实景图删除
            liveDel(data) {
                return http.get('/api/adminApi/admin/realistic/del?id=' + data)
            },
            // 实景图详情
            ImgDetails(data) {
                return http.get('/api/adminApi/admin/realistic/info?id=' + data)
            },
            // 实景图创建 
            realisticImg_create(data) {
                return http.post('/api/adminApi/admin/realistic/create', data)
            },
            // 实景图编辑
            realisticImg_updata(data) {
                return http.post('/api/adminApi/admin/realistic/update', data)
            },
            // 实景图创建 根据视频id 获取视频链接
            get_video_url(id) {
                return http.get('/api/adminApi/tool/get_video_url?videoId=' + id)
            },
            // 实景图创建 根据视频id 删除视频
            del_video_url(id) {
                return http.get('/api/adminApi/tool/del_video?videoId=' + id)
            },
            // 实景图审核列表
            process_list(data) {
                return http.get('/api/adminApi/admin/realistic_log/lst?limit=' + data.limit + '&page=' + data.page + '&searchValue=' + data.searchValue + '&type=' + data.type)
            },
            // 实景图审核详情
            process_detail(data) {
                return http.get('/api/adminApi/admin/realistic_log/info?id=' + data)
            },
            // 实景图审核
            process_status(data) {
                return http.post('/api/adminApi/admin/realistic_log/status', data)
            },
            // 实景图耽搁详情
            process_details(data) {
                return http.get('/api/adminApi/admin/realistic/info?id=' + data)
            },
            // 实景图上下架
            sj_status(data) {
                return http.get('/api/adminApi/admin/realistic/status?id=' + data)
            },
            // 实景图上下架
            sj_status(data) {
                return http.get('/api/adminApi/admin/realistic/status?id=' + data)
            },
            // 方案专题方案列表
            programmeLst(data) {
                return http.get('/api/adminApi/tool/programmeLst?limit=' + data.limit + '&searchValue=' + data.searchValue + '&page=' + data.page)
            },
            // 方案专题创建
            programmecreate(data) {
                return http.post('/api/adminApi/admin/programme_group/create', data)
            },
            // 方案专题列表
            programmelist(data) {
                return http.get('/api/adminApi/admin/programme_group/lst?limit=' + data.limit + '&page=' + data.page)
            },
            // 方案专题列表
            programmedel(data) {
                return http.get('/api/adminApi/admin/programme_group/del?id=' + data)
            },
            // 方案专题详情
            programmeinfo(data) {
                return http.get('/api/adminApi/admin/programme_group/info?id=' + data)
            },
            // 方案专题编辑
            programmeupdate(data) {
                return http.post('/api/adminApi/admin/programme_group/update', data)
            },
            // 获取banner跳转链接类型
            banner_link(data) {
                let url = '/api/adminApi/tool/banner_link';
                if (data.type === 5) {
                    url += `?limit=${data.limit}&type=${data.type}&page=${data.page}`;
                } else if (data.type === 4 || data.type === 10) {
                    url += `?limit=${data.limit}&type=${data.type}&page=${data.page}&searchValue=${data.searchValue}`;
                } else if (data.type) {
                    url += `?type=${data.type}`;
                }
                return http.get(url)
            },
            // 创建首页banner    
            banner_reate(data) {
                return http.post('/api/adminApi/admin/banner/create', data)
            },
            // 首页banner列表    
            banner_list(data) {
                return http.get('/api/adminApi/admin/banner/lst?page=' + data.page + '&limit=' + data.limit + '&banner_type=' + data.banner_type)
            },
            // 首页banner编辑详情
            bannerDetails(data) {
                return http.get('/api/adminApi/admin/banner/info?id=' + data);
            },
            // 首页banner编辑提交
            bannerUpdate(data) {
                return http.post('/api/adminApi/admin/banner/update', data);
            },
            // 删除banner
            delbanner(data) {
                return http.get('/api/adminApi/admin/banner/del?id=' + data);
            },
            // 排序banner
            sortbanner(data) {
                return http.get('/api/adminApi/tool/sort?id=' + data.id + '&sort=' + data.sort);
            },
            // 方案同步
            syncFangan(data) {
                return http.get('api/adminApi/tool/syncFangan');
            },
            // 实景图同步
            syncSjt(data) {
                return http.get('api/adminApi/tool/syncSjt');
            },
            //社区置顶  发布
            up_status(data) {
                return http.post('api/adminApi/admin/an/up_status', data);
            },
            // 课程 excel
            courseExcel(data) {
                return http.get('api/adminApi/tool/courseExcel?id=' + data);
            },
            // 视频 excel
            videoExcel(data) {
                return http.get('api/adminApi/tool/videoExcel');
            },
            // 集训营 excel
            campExcel(data) {
                return http.get('api/adminApi/tool/campExcel?id=' + data);
            },
            // 作废以及公告
            his_an_lst(data) {
                return http.get('api/adminApi/tool/his_an_lst?searchValue=');
            },
            // 导入用户
            userImport(data) {
                return http.post('api/adminApi/tool/userImport', data);
            },
            // 获取公告列表
            get_an_lst(data) {
                return http.get('api/adminApi/tool/get_an_lst');
            },
            // 通知创建
            noticeCreate(data) {
                return http.post('api/adminApi/admin/nt/create', data);
            },
            // 修改通知
            noticeUpdata(data) {
                return http.post('api/adminApi/admin/nt/update', data);
            },
            // 修改通知
            noticeList(data) {
                return http.get('/api/adminApi/admin/nt/lst?limit=' + data.limit + '&page=' + data.page);
            },
            // 删除通知
            noticedel(data) {
                return http.get('/api/adminApi/admin/nt/del?id=' + data);
            },
            // 通知生效
            noticeStatus(data) {
                return http.get('/api/adminApi/admin/nt/up_s?id=' + data.id + '&status=' + data.status);
            },
            // 兑换商城创建
            shopping_c(data) {
                return http.post('/api/adminApi/admin/ex/c', data);
            },
            // 兑换商城编辑
            shopping_u(data) {
                return http.post('/api/adminApi/admin/ex/u', data);

            },
            // 兑换商城列表
            shopping_l(data) {
                return http.get('/api/adminApi/admin/ex/l?searchValue=' + data.searchValue + '&limit=' + data.limit + '&page=' + data.page);
            },
            // 兑换商城删除
            shopping_d(data) {
                return http.get('/api/adminApi/admin/ex/d?id=' + data);
            },
            // 兑换商城配置
            shopping_p(data) {
                return http.post('/api/adminApi/admin/ex/p', data);
            },
            // 兑换列表
            exchange_list(data) {
                return http.get('/api/adminApi/admin/exl/l?limit=' + data.limit + '&page=' + data.page + '&is_send=' + data.is_send + '&search_value=' + data.search_value);
            },
            // 兑换记录处理
            exchange_dispose(data) {
                return http.post('/api/adminApi/admin/exl/u', data);
            },
            // 解除账号
            removeAccount(data) {
                return http.get('/api/adminApi/admin/user/ub?user_id=' + data);
            },
            // 方案投稿配置
            programmeContribute(data) {
                return http.get('/api/adminApi/admin/tg/i');
            },
            // 投稿配置保存
            saveContribute(data) {
                return http.post('/api/adminApi/admin/tg/u', data);
            },
            // 方案投稿获取详情
            contribute(data) {
                return http.get('/api/adminApi/admin/tg/ii?id=' + data);
            },
            // 方案投稿提交
            contributeSubmit(data) {
                return http.post('/api/adminApi/admin/tg/a', data);
            },
            // 方案投稿列表
            contributelist(data) {
                return http.get('/api/adminApi/admin/tg/l?limit=' + data.limit + '&page=' + data.page + '&type=' + data.type);
            },
            // 方案投稿打分详情
            ScoringDetails(data) {
                return http.get('/api/adminApi/tool/sub_log?id=' + data);
            },
            // 评论列表
            commentList(data) {
                return http.get('/api/adminApi/admin/pl/l?limit=' + data.limit + '&page=' + data.page + '&type=' + data.type);
            },
            // 敏感词详情
            sensitiveTag(data) {
                return http.get('/api/adminApi/admin/pl/i');
            },
            // 删除评论
            commentDel(data) {
                return http.get('/api/adminApi/admin/pl/d?id=' + data.id + '&type=' + data.type);
            },
            // 增加敏感词
            sensitiveTerms(data) {
                return http.post('/api/adminApi/admin/pl/s', data);
            },
            // 实景图投稿须知详情
            MatteDetail(data) {
                return http.get('/api/adminApi/tool/r_i');
            },
            // 实景图投稿须知
            Matter(data) {
                return http.post('/api/adminApi/admin/realistic_log/sub_c', data);
            },
            // 产品中心关联模型弹框title  
            modelTitle(data) {
                return http.get('/api/adminApi/tool/gc?index=' + data);
            },
            // 产品中心关联模型弹框列表
            model_productList(data) {
                return http.post('/api/adminApi/tool/get_product', data);
            },
            // 产品中心新建分类
            classify_product(data) {
                let parameter = {
                    'title': data.name,
                    'pid': data.parentId || 0,
                };
                return http.post('/api/adminApi/admin/pd/cc', parameter);
            },
            // 产品中心修改分类
            updates_product(data) {
                let parameter = {
                    'title': data.name,
                    'pid': data.parentId || 0,
                    'id': data.id
                };
                return http.post('/api/adminApi/admin/pd/uc', parameter);
            },
            // 产品中心分类删除
            del_classify_product(data) {

                return http.get('/api/adminApi/admin/pd/dc?id=' + data.id);
            },
            // 产品中心分类列表
            classify_product_list(data) {
                return http.get(`/api/adminApi/admin/pd/cl?page=${data.page}&limit=${data.limit}`,);
            },
            // 产品中心父级分类
            classify_product_parent(data) {
                return http.get(`/api/adminApi/tool/get_p_cate`,);
            },
            // 产品中心创建时  选择分类
            classify_product_create(data) {
                return http.get(`/api/adminApi/tool/get_cp_cate`,);
            },
            // 产品中心创建时  实景图
            RealisticView_list(data) {
                return http.get(`/api/adminApi/tool/get_r_lst?page=${data.page}&limit=${data.limit}&name=${data.searchValue}`,);

            },
            // 创建产品
            product_create(data) {
                return AbortController.post(`/api/adminApi/admin/pd/c`, data, { removeCache: true });
            },
            // 创建产品列表
            product_list(data) {
                return http.get(`/api/adminApi/admin/pd/l?page=${data.page}&limit=${data.limit}&name=${data.searchValue}`,);
            },
            // 产品列表删除
            product_del(data) {
                return http.get(`/api/adminApi/admin/pd/d?id=${data.id}`,);
            },
            // 编辑产品
            update_product(data) {
                return http.post(`/api/adminApi/admin/pd/u`, data);
            },
            // 产品详情
            detail_product(data) {
                return http.get(`/api/adminApi/admin/pd/i?id=${data}`);
            },
            // 产品详情
            detail_top(data) {
                return http.get(`/api/adminApi/admin/pd/us?id=${data}`);
            },
            // 产品专题   选择产品
            product_selection() {
                return http.get(`/api/adminApi/tool/gp_l`);
            },
            // 产品专题创建   
            product_Special_product_create(data) {
                return AbortController.post(`/api/adminApi/admin/pd/pgc`, data, { removeCache: true });
            },
            // 产品专题修改
            product_Special_product_updata(data) {
                return http.post(`/api/adminApi/admin/pd/pgu`, data);
            },
            // 产品专题详情
            product_Special_product_detail(data) {
                return http.get(`/api/adminApi/admin/pd/pgi?id=${data}`);
            },
            // 产品专题列表删除
            product_Special_product_del(data) {
                return http.get(`/api/adminApi/admin/pd/pgd?id=${data}`);
            },
            // 产品专题列表
            product_Specia_list(data) {
                return http.get(`/api/adminApi/admin/pd/pgl?page=${data.page}&limit=${data.limit}&name=${data.searchValue}`,);
            },
            // 产品专题列表置顶
            product_Special_product_top(data) {
                return http.get(`/api/adminApi/admin/pd/pgt?id=${data}`);
            },
            // 产品专题列表上下架
            product_Special_product_status(data) {
                return http.get(`/api/adminApi/admin/pd/pgs?id=${data}`);
            },
            // 酷家乐账号列表
            KJL_accountNumber(data) {
                return http.get(`/api/adminApi/admin/user/kl?page=${data.page}&limit=${data.limit}&name=${data.searchValue}&type=${data.type}`,);
            },
            // 解除酷家乐账号列表
            KJL_remove(data) {
                return http.get(`/api/adminApi/admin/user/ub?user_id=${data}`,);
            },
            // 同步新账号列表
            KJL_synchronous(data) {
                return http.post(`/api/adminApi/tool/getKjlUser`, data);
            },
            // 同步新账号
            KJL_syn(data) {
                return http.get(`/api/adminApi/admin/user/sku?p=` + data);
            },
            // 页面样式系统模板
            sys_template(data) {
                return http.get(`/api/adminApi/tool/sys_template`);
            },
            // 设计大赛创建
            templateCreate(data) {
                return AbortController.post(`/api/adminApi/admin/ds/c`, data, { removeCache: true });
            },
            // 设计大赛列表
            CompetitionList(data) {
                return http.get(`/api/adminApi/admin/ds/l?page=${data.page}&limit=${data.limit}&searchValue=${data.searchValue}`,);
            },
            // 设计大赛列表
            CompetitionDetials(data) {
                return http.get(`/api/adminApi/admin/ds/i?id=${data}`,);
            },
            // 设计大赛列表修改
            CompetitionUpdata(data) {
                return AbortController.post(`/api/adminApi/admin/ds/u`, data, { removeCache: true });
            },
            // 设计大赛列表删除
            CompetitionDel(data) {
                return AbortController.get(`/api/adminApi/admin/ds/d?id=${data}`, { removeCache: true });
            },
            // 设计大赛列表发布
            CompetitionUs(data) {
                return http.get(`/api/adminApi/admin/ds/us?id=${data}`,);
            },
            // 作品管理列表
            getworksList(data) {
                return http.get(`/api/adminApi/admin/ds/cpl?page=${data.page}&limit=${data.limit}&order=${data.order}&comp_id=${data.comp_id}`,);
            },
            // 作品管理上下架
            getworksGrounding(data) {
                return http.get(`/api/adminApi/admin/ds/cup?id=${data}`,);
            },
            // 作品管理获取打分项
            worksScoring(data) {
                return http.get(`/api/adminApi/admin/ds/dfl?comp_id=${data.comp_id}&pro_id=${data.pro_id}`,);
            },
            // 打分项提交
            scoringSubmit(data) {
                return http.post(`/api/adminApi/admin/ds/dflc`, data);
            },
            // 大赛数据统计
            competitionData(data) {
                return http.get(`/api/adminApi/admin/ds/stj?comp_id=${data}`);
            },
            // 打分方案详情
            ScoringDetails(data) {
                return http.get(`/api/adminApi/admin/ds/iin?id=${data}`);
            },
            // 实景图征集 系统封面
            getCollectCover(data) {
                return http.get(`/api/adminApi/tool/si`);
            },
            // 实景图征集创建
            CollectCreate(data) {
                return AbortController.post(`/api/adminApi/admin/ds/rc`, data, { removeCache: true });
            },
            // 实景图征集修改
            CollectUpdata(data) {
                return AbortController.post(`/api/adminApi/admin/ds/ru`, data, { removeCache: true });
            },
            // 实景图征集详情
            CollectDetails(data) {
                return AbortController.get(`/api/adminApi/admin/ds/ri?id=` + data, { removeCache: true });
            },
            // 设计大赛列表
            CollectList(data) {
                return http.get(`/api/adminApi/admin/ds/rl?page=${data.page}&limit=${data.limit}&searchValue=${data.searchValue}`,);
            },
            // 实景图征集删除
            CollectListDel(data) {
                return AbortController.get(`/api/adminApi/admin/ds/rd?id=` + data, { removeCache: true });
            },
            // 实景图征集发布
            CollectLisUs(data) {
                return http.get(`/api/adminApi/admin/ds/rup?id=${data}`,);
            },
            //  实景图征集作品管理列表
            CollectworksList(data) {
                return http.get(`/api/adminApi/admin/ds/rrl?page=${data.page}&limit=${data.limit}&collection_id=${data.collection_id}&name=${data.searchValue}&type=${data.type}`,);
            },
            // 实景图征集发布
            examineDetails(data) {
                return http.get(`/api/adminApi/admin/ds/rii?id=${data}`,);
            },
            // 实景图征集发布
            Collectexamine(data) {
                return AbortController.get(`/api/adminApi/admin/ds/ra?id=${data.id}&type=${data.type}`, { removeCache: true });
            },
            // 数据统计
            CollectData(data) {
                return http.get(`/api/adminApi/admin/ds/rstj?id=${data}`);
            },
            // 数据统计
            matchPreview(data = 16) {
                // let data = JSON.parse(sessionStorage.getItem('previewDetail'))

                // console.log(':', data)
                return;
                if (data) {
                    return http.get(`/api/webApi/ds/i/${data}`);
                } else {
                    return new Promise((resolve, reject) => {
                        try {
                            let data = sessionStorage.getItem('matchPreview')
                            resolve({
                                button_color: "",
                                button_colors: '',
                                status: 1,
                                is_zdy: '',
                                name: '',
                                application_start_at: "",
                                application_end_at: "",
                                vote_start_at: "",
                                vote_end_at: "",
                                publish_at: "",
                                prize: '',
                                scoring_way: '',
                                ask: '',
                                bg_cover: '',
                                view_info: {
                                    bg_color: '',
                                    color_join: '',
                                    lc_bg_img: '',
                                    line_color: "",
                                    line_icon: '',
                                    navigation_color: '',
                                    node_icon: '',
                                    select_ziti_color: "",
                                    title_color: "",
                                    ziti_color: "",


                                },
                                zdy_lst: []
                            })
                        } catch (err) {
                            reject(err)
                        }
                    });
                }

            },
        };
    },
};
