<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" :width="dialog_width" @close="exit" :close-on-click-modal="false" >
      <slot name="content"></slot>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['title', 'switch', 'button', 'width'],
  data() {
    return {
      button_type: true,
      dialogFormVisible: false,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          return false;
        }
      });
    },
    exit() {
      this.$emit('handleUpdate', false);
     
    }
  },
  watch: {
    switch: {
      handler(newValue, oldValue) {
        this.dialogFormVisible = newValue
      }
    },
    button: {
      handler(newValue, oldValue) {
        this.button_type = newValue
      },
      immediate: true
    }
  },
  computed: {
    dialog_width() {
      let getappWidth = this.$store.state.appWidth;
      let data;
      const percentage = this.width || '50%'; 
      const decimalValue = parseFloat(percentage) / 100;
      data = decimalValue * getappWidth;
      return data + 'px'
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 10px;
}
</style>