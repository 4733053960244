import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    name: 11,
    navType: 'refined',
    appWidth: 0,
    list: [],
    isRotated:true
  },
  mutations: {
    setNav(state, data) {
      state.navType = data;
      sessionStorage.setItem('navType', data);
    },
    setappWidth(state, data) {
      state.appWidth = data
    },
    setlist(state, data, index) {
      if (index) {
        state.list.splice(index, 1, data)
      } else {
        state.list = data
      }
    },
    setisRotated(state, data){
      state.isRotated = data
      
    }
  },
  getters: {
  },
  actions: {
  },
  modules: {},
});
// 定义一个函数用于动态注册模块
function registerModule(store, moduleName) {
  const moduleConfig = require(`./${moduleName}`);
  if (moduleConfig) {
    store.registerModule(moduleName, moduleConfig.default || moduleConfig);
  }
}
registerModule(store, 'setting');
registerModule(store, 'role');

export default store;
