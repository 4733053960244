
import axios from 'axios';
import loading from '../utils/loading';
import Vue from 'vue'
import url from './requestUrl';
const instance = axios.create({
    baseURL: url,
    timeout: 100000, // 设置请求超时时间,
});
instance.interceptors.request.use(config => {
    // 登录不需要token
    if (config.url !== '/api/adminApi/login' && config.url !== '/api/adminApi/pcLoginAdmin') {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = token;
        }
    }
    return config;
}, err => {
    return Promise.reject(err)
});
instance.interceptors.response.use(config => {
    loading.methods.hideLoading()
    return config
}, err => {
    
    console.log('error:', err.response.data)
    loading.methods.hideLoading()  
    Vue.prototype.$message.error( err.response.data.msg ? err.response.data.msg : err.response.data.message  ? err.response.data.message  : '服务器错误!');
    return Promise.reject(err.response);
})
export default instance;

